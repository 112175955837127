import React, { useRef } from "react";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";

const QueueType = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { className, isMobile, disable, options, filters, isDefault, onFilterChange, disableTooltip } = props;

  if (disable && disable.includes("queueType")) {
    return null;
  }

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Queue Type")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Queue Type")}
          group={"queue-type"}
          isMobile={isMobile}
          className={`filter-select queue-type ${isDefault}`}
          options={options}
          onChange={(selected) => {
            onFilterChange("queueType", selected);
          }}
          formatOptionLabel={({ label }) => <div>{t(label)}</div>}
          value={filters.queueType}
          width={150}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default QueueType;
