import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, Link } from "react-router-dom";
import { TooltipContainer } from "@outplayed/tooltips";
import { TIER_LIST_ROLE_OPTIONS } from "query_parameters/filter_options";

import { useTranslation } from "react-i18next";

const TierListRoleSplit = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { className, deactivate, disable, filters, disableTooltip } = props;

  const displayRoleFilters = () => {
    return TIER_LIST_ROLE_OPTIONS.map((role) => {
      const isSelectedRole = role.path === location.pathname;
      const selectorClassNames = classNames("filter-tooltip-wrapper", {
        "role-filter": true,
        active: isSelectedRole,
      });

      return (
        <TooltipContainer key={role.value} tooltipInfo={t(role.label)} disableTooltip={disableTooltip}>
          <Link className={selectorClassNames} to={{ pathname: role.path, search: location.search }}>
            {isSelectedRole && role.imgActive ? role.imgActive : role.img}
          </Link>
        </TooltipContainer>
      );
    });
  };

  if (!deactivate && disable && disable.includes("role")) {
    return null;
  }

  if (!deactivate && disable && !disable.includes("queueType")) {
    const queueType = filters.queueType;
    if (queueType === "normal_aram" || queueType === "nexus_blitz") {
      return null;
    }
  }

  return (
    <div className={classNames("role-filter-container", { deactivated: deactivate }, className)}>{displayRoleFilters()}</div>
  );
};

export default TierListRoleSplit;
