import React, { useRef } from "react";
import { TooltipContainer } from "@outplayed/tooltips";
import DefaultSelect from "components/DefaultSelect";
import { useTranslation } from "react-i18next";

const Rank = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { className, isMobile, disable, options, filters, isDefault, onFilterChange, disableTooltip, disableByQueueType } = props;

  if (disable && disable.includes("rank")) {
    return null;
  }

  if (!disableByQueueType) {
    if (
      filters.queueType === "normal_aram" ||
      filters.queueType === "arurf" ||
      filters.queueType === "nexus_blitz" ||
      filters.queueType === "normal_blind_5x5" ||
      filters.queueType === "normal_draft_5x5" ||
      filters.queueType === "quickplay"
    ) {
      return null;
    }
  }

  let isDisabled =
    disableByQueueType &&
    (filters.queueType === "normal_aram" ||
      filters.queueType === "arurf" ||
      filters.queueType === "nexus_blitz" ||
      filters.queueType === "normal_blind_5x5" ||
      filters.queueType === "normal_draft_5x5" ||
      filters.queueType === "quickplay");

  const formatOptionLabel = ({ value, label, img }) => (
    <div className="rank-option">
      <img className="rank-img" style={value === "overall" ? { width: "18px", marginLeft: 1 } : {}} src={img} alt={label} />
      <span style={{ marginLeft: "10px" }}>{t(label)}</span>
    </div>
  );

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Rank")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Rank")}
          group={"rank"}
          isMobile={isMobile}
          className={`filter-select filter-select_rank ${isDefault}`}
          options={options}
          onChange={(selected) => {
            onFilterChange("rank", selected);
          }}
          value={filters.rank || filters.mmr}
          formatOptionLabel={formatOptionLabel}
          disabled={isDisabled}
          width={170}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default Rank;
