import React, { Component } from "react";
import Modal from "components/Modal";
import FilterModal from "components/FilterManager/FilterModal";
import Switch from "react-switch";
import { getTierListPickRateCutoff } from "lib/tierHelper";

class ShowAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: props.filters.allChampions === "true" ? true : false,
    };
  }

  onChangeHandler = () => {
    this.setState({ showAll: !this.state.showAll });
  };

  onCancel = (e) => {
    this.setState({ showAll: this.props.filters.allChampions === "true" ? true : false });
  };

  onApply = () => {
    const { showAll } = this.state;
    const { onFilterChange, filters } = this.props;

    if (filters.allChampions !== `${showAll}`) {
      onFilterChange("allChampions", {
        value: `${showAll}`,
      });
    }
  };

  render() {
    const { showAll } = this.state;
    const { isMobile, enable, filters, onFilterChange } = this.props;

    if (!enable || !enable.includes("showAll")) {
      return null;
    }

    const active = filters.allChampions === "true" ? true : false;

    const button = <div className={`filter-select filter-select_show-all ${active ? "active" : ""}`}>Show All</div>;

    if (isMobile) {
      return (
        <FilterModal
          button={button}
          title={"Show All Champions"}
          onChange={this.onChangeHandler}
          onApply={this.onApply}
          onCancel={this.onCancel}
        >
          <div className="filter-modal_content__show-all">
            <div className="switch-row">
              <div>{"Don't show"}</div>
              <Switch
                className="switch-container"
                width={72}
                height={36}
                handleDiameter={30}
                onColor={"#222238"}
                offColor={"#222238"}
                onHandleColor={"#08A6FF"}
                offHandleColor={"#FF4E50"}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={this.onChangeHandler}
                checked={showAll}
              />
              <div>{"Show"}</div>
            </div>
            <div className="filter-description">
              This option allows you to display champions with pick rates less than {getTierListPickRateCutoff()}%.
            </div>
          </div>
        </FilterModal>
      );
    }

    return (
      <Modal
        dataTip={"Show All Champions"}
        modalClassNames={"select_modal__modal-window__filter"}
        button={button}
        onApply={this.onApply}
        onCancel={this.onCancel}
      >
        <div className="modal_toggle">
          <div className="filter-description">
            <div className="title">Show All Champions</div>
            <div className="sub-text">
              This option allows you to display champions with pick rates less than {getTierListPickRateCutoff()}%.
            </div>
          </div>
          <Switch
            width={72}
            height={36}
            handleDiameter={30}
            onColor={"#222238"}
            offColor={"#222238"}
            onHandleColor={"#08A6FF"}
            offHandleColor={"#FF4E50"}
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={this.onChangeHandler}
            checked={showAll}
          />
        </div>
      </Modal>
    );
  }
}

export default ShowAll;
