import React, { useRef } from "react";
import classNames from "classnames";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import ReactSVG from "react-svg";
import { getApiRole } from "lib/general-helper";
import { useTranslation } from "react-i18next";

const Role = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();

  const {
    className,
    deactivate,
    disable,
    role,
    options,
    filters,
    isDefault,
    onFilterChange,
    disableTooltip,
    disableByQueueType,
    isMobile,
  } = props;

  if (!deactivate && disable && disable.includes("role")) {
    return null;
  }
  let isDisabled = disableByQueueType;

  const formatOptionLabel = ({ value, label, img, imgActive }) => (
    <div className="role-value">
      {img}
      <div style={{ marginLeft: "12px" }}>{t(label)}</div>
    </div>
  );

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Role")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Role")}
          group={"role"}
          isMobile={isMobile}
          className={`filter-select ${isDefault}`}
          options={options}
          onChange={(selected) => {
            onFilterChange("role", selected);
          }}
          value={filters.role || role}
          formatOptionLabel={formatOptionLabel}
          disabled={isDisabled}
          width={120}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default Role;
