import React, { useRef } from "react";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import ReactSVG from "react-svg";
import { getBlockImg, getImg } from "lib/region_helper";
import { useTranslation } from "react-i18next";

const Region = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { className, isMobile, disable, options, filters, isDefault, onFilterChange, disableTooltip } = props;

  if (disable && disable.includes("region")) {
    return null;
  }

  const formatOptionLabel = ({ value, label, flag, img }) => (
    <div className={`region-option`} key={value}>
      {value === "world" ? (
        <ReactSVG wrapperClassName="region-img-wrapper" className={`region-img ${flag ? "flag-img" : ""}`} path={img} />
      ) : (
        <img src={getBlockImg(value)} />
      )}

      <span style={{ marginLeft: "10px" }}>{t(label)}</span>
    </div>
  );

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Region")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Region")}
          group={"region"}
          isMobile={isMobile}
          className={`filter-select ${isDefault}`}
          options={options}
          onChange={(selected) => {
            onFilterChange("region", selected);
          }}
          value={filters.region || filters.regionId}
          formatOptionLabel={formatOptionLabel}
          width={120}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default Region;
